import { QueryHookOptions } from "@apollo/client";
import {
  useGetAccount,
  useGetCareprovider,
  useGetCareseeker,
} from "apollo/hooks/queries";
import { getFetchPolicy } from "apollo/hooks/utils";
import { TRACK_EVENTS } from "core/consts";
import { TOKEN_STATUS, checkTokenStatus } from "core/model/accounts";
import { getErrorMessage } from "core/model/utils/errors";
import { getTopLevelDomain } from "core/model/utils/urls";
import {
  CareproviderConfig,
  Careseeker,
  CareseekerConfig,
  Country,
} from "core/types";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import {
  CAREPROVIDER_CHANGED,
  COUNTRY_CHANGED,
  LOGGED_OUT,
} from "reduxentities/store/slices/authSlice";
import {
  State,
  selectActiveRoles,
  selectCredentials,
  selectLoggedCareproviderId,
  selectLoggedCareseeker,
  selectLoggedInAccountId,
  selectPrivateKey,
  selectToken,
  selectTokenType,
} from "./index";

export const useLoggedCareseeker = (
  options?: QueryHookOptions<
    { careseeker: Careseeker },
    { id: number | undefined }
  >,
) => {
  const loggedCareseeker = useSelector(selectLoggedCareseeker);
  const [, careseeker] = useGetCareseeker(
    loggedCareseeker?.id,
    { withAdminFields: true },
    {
      ...options,
      fetchPolicy: getFetchPolicy(options?.fetchPolicy ?? "cache-only"),
    },
  );

  return careseeker;
};

export const useLoggedCareseekerConfig = (
  configProperty: keyof CareseekerConfig,
) => {
  const loggedCareseeker = useLoggedCareseeker();
  return loggedCareseeker?.config?.[configProperty];
};

export const useLoggedCareprovider = () => {
  const careproviderId = useSelector(selectLoggedCareproviderId);
  const [, careprovider] = useGetCareprovider({
    id: careproviderId,
    options: { fetchPolicy: getFetchPolicy("cache-only") },
  });
  return careprovider;
};

export const useLoggedCareproviderConfig = (
  configProperty: keyof CareproviderConfig,
) => {
  const loggedCareprovider = useLoggedCareprovider();
  return loggedCareprovider?.config?.[configProperty];
};

export const useLoggedCareproviderId = () => {
  return useSelector(selectLoggedCareproviderId);
};

export const useCareproviderChanged = () => {
  const { trackEvent } = useTracking();

  const dispatch = useDispatch();
  const careproviderChanged = useCallback(
    (careproviderId: number, country?: string) => {
      trackEvent({
        name: TRACK_EVENTS.CAREPROVIDER_CHANGED,
        careprovider_id_new: careproviderId,
      });

      dispatch(CAREPROVIDER_CHANGED({ careproviderId, country }));
    },
    [dispatch],
  );
  return careproviderChanged;
};

export function useLoggedInAccount() {
  return useSelector((state: State) => state?.auth?.identification?.account);
}

export function useConnecterWithLoggedAccount(options?: {
  cacheOnly?: boolean;
}) {
  const accountId = useSelector(selectLoggedInAccountId);
  const [, account] = useGetAccount(accountId || -2, {
    fetchPolicy: options?.cacheOnly ? "cache-only" : undefined,
  });
  return account;
}

export function useConnecterWithCareseeker() {
  const account = useConnecterWithLoggedAccount();
  const careseeker = useSelector(selectLoggedCareseeker);
  const careseekerId = careseeker?.id;
  const privateKey = useSelector(selectPrivateKey);
  const activeRoles = useSelector(selectActiveRoles);
  return {
    account,
    careseeker,
    careseekerId,
    privateKey,
    activeRoles,
  };
}

export const useCountry = (): Country => {
  try {
    return useSelector(
      (state: State) => state?.auth?.country || getTopLevelDomain(),
    );
  } catch (err) {
    const country = getTopLevelDomain();
    console.error(
      `Hook call error, retrieving ontologies for ${country}: `,
      getErrorMessage(err),
    );
    return country;
  }
};

export const useCountryChanged = () => {
  const dispatch = useDispatch();

  const countryChanged = useCallback(
    (currentCountry: string | undefined): void => {
      dispatch(COUNTRY_CHANGED({ country: currentCountry }));
    },
    [dispatch],
  );
  return countryChanged;
};

export const useGetLanguage = () => {
  const language = useSelector((s: State) => s.settings?.language);
  return language;
};

export function useTokenStatusCheck() {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const credentials = useSelector(selectCredentials);

  useEffect(() => {
    const tokenStatus = checkTokenStatus(credentials);
    if (tokenStatus === TOKEN_STATUS.EXPIRED) {
      trackEvent({ name: TRACK_EVENTS.ROUTE_SESSION_EXPIRED });
      dispatch(LOGGED_OUT());
    }
  }, [credentials]);
}

export function useSelectQueryToken() {
  const tokenType = useSelector(selectTokenType);
  let token = useSelector(selectToken);
  token = tokenType === "query" ? token : undefined;
  return token;
}
