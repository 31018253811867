import { createSlice } from "@reduxjs/toolkit";
import { getFirstProviderRole } from "core/model/careproviders";
import {
  Careprovider,
  CareproviderRoles,
  Careseeker,
  DispatchAction,
} from "core/types";
import { getUnixTime, subDays } from "date-fns";
import {
  StateType,
  findMatchingCareproviderFromState,
  findMatchingCareseekerFromState,
  getFirstSeekerRole,
  setAuthState,
  setCareprovider,
  setCareseeker,
  setCareseekerRoles,
  setCountry,
  setProviderSearchCareseeker,
} from "../utils/authUtils";

const yesterday = getUnixTime(subDays(new Date(), 1));

const initialState: StateType = {
  credentials: { token: null },
  identification: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    TOKEN_CHANGED(state, action: DispatchAction) {
      let updatedState = setAuthState(state, action);

      const existingCareprovider = findMatchingCareproviderFromState(
        action?.payload?.identification?.careprovider_roles,
        state,
      );
      const firstProviderInRoles = getFirstProviderRole(
        action.payload.identification.careprovider_roles,
      );
      const careproviderFromRolePayload = firstProviderInRoles?.careprovider;

      const careproviderId =
        action.payload.careproviderToLog ||
        existingCareprovider ||
        careproviderFromRolePayload?.id;

      const careprovider: Careprovider | undefined =
        action?.payload?.identification?.careprovider_roles?.find(
          (role: CareproviderRoles) => role?.id === careproviderId,
        )?.careprovider;

      updatedState = setCareprovider({
        careproviderId,
        careproviderName: careprovider?.name,
        state: updatedState,
      });

      const existingCareseeker = findMatchingCareseekerFromState(
        action?.payload?.identification?.careseeker_roles,
        state,
      );

      const firstSeekerInRoles = getFirstSeekerRole(
        action.payload.identification.careseeker_roles,
      )?.careseeker;
      const careseekerId = existingCareseeker || firstSeekerInRoles?.id;

      const careseeker: Careseeker | undefined =
        action.payload.identification.careseeker_roles?.find(
          (role: CareproviderRoles) => role?.id && role?.id === careseekerId,
        )?.careseeker;

      updatedState = setCareseeker(
        careseekerId,
        careseeker?.name ?? "",
        updatedState,
      );

      return setCountry(
        careseeker?.address?.country ?? careprovider?.address?.country,
        updatedState,
      );
    },

    CARESEEKER_CHANGED(state, action: DispatchAction) {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareseeker(
        action.payload.careseeker_id,
        action.payload?.careseeker_name,
        updatedState,
      );
    },

    CAREPROVIDER_CHANGED(state, action: DispatchAction) {
      const updatedState = setCountry(action.payload?.country, state);
      return setCareprovider({
        careproviderId: action.payload.careproviderId,
        state: updatedState,
      });
    },

    SET_CARESEEKER(state, action: DispatchAction) {
      const updatedRoles = setCareseekerRoles(state, action);
      return setProviderSearchCareseeker(updatedRoles, action);
    },

    COUNTRY_CHANGED(state, action: DispatchAction) {
      return setCountry(action.payload?.country, state);
    },

    LOGGED_OUT(state) {
      return {
        ...state,
        careprovider: undefined,
        careprovider_name: undefined,
        careseeker: undefined,
        careseeker_name: undefined,
        credentials: { token: null },
        active_roles: undefined,
        identification: null,
      };
    },

    _TEST_EXPIRE_SESSION(state) {
      return {
        ...state,
        credentials: {
          token: state.credentials?.token ?? null,
          expiration: yesterday,
        },
      };
    },
  },
});

export const {
  _TEST_EXPIRE_SESSION,
  CAREPROVIDER_CHANGED,
  CARESEEKER_CHANGED,
  COUNTRY_CHANGED,
  LOGGED_OUT,
  SET_CARESEEKER,
  TOKEN_CHANGED,
} = authSlice.actions;

export default authSlice.reducer;
